import Form from "./components/Form"
import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
// import KanyeMove from './components/KanyeMove';

function App() {
  return (
    <div className=''>
      <Header />
      <Form />
      <Footer />
      
      
      
    </div>
  );
}

export default App;